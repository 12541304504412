import axios from "axios";
import { sha256 } from "js-sha256";
import { LogoutHelper } from "../utils/helpers";
import {
  createAdminData,
  createParentData,
  createTeachersData,
} from "../utils/dataFormatting";
import {
  DEFAULT_DASHBOARD_DATA,
  DEFAULT_MESSAGES,
  DEFAULT_SUBTITLES,
} from "../utils/constants";

//TODO: Set baseUrl as environment variable
const baseUrl = process.env.REACT_APP_SKYBRARY_API_URL;
const baseUrlConsumer = process.env.REACT_APP_CONSUMER_DOMAIN;
const v6BasePath = "/rrkidz/v6";
const defaultCurrency = "usd";

const makeGet = async (
  path: string,
  queryParams?: Record<string, any>,
  config?: any,
): Promise<object> => {
  let queryString = "";
  if (undefined !== queryParams) {
    queryString = "?" + new URLSearchParams(queryParams);
  }
  const response = await axios
    .get(baseUrl + path + queryString, config)
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((error) => {
      if (error.response.status === 401) {
        LogoutHelper();
      }
      return Promise.reject(error);
    });
  return response;
};

const makePost = async (
  path: string,
  data?: any,
  queryParams?: Record<string, any>,
  config?: any,
): Promise<object> => {
  let queryString = "";
  if (undefined !== queryParams) {
    queryString = "?" + new URLSearchParams(queryParams);
  }
  const response = await axios
    .post(baseUrl + path, data, config)
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((error) => {
      if (error.response.status === 401 && path !== "/oauth/token") {
        LogoutHelper();
      }
      return Promise.reject(error);
    });
  return response;
};

const makeDelete = async (
  path: string,
  queryParams?: Record<string, any>,
  config?: any,
): Promise<object> => {
  let queryString = "";
  if (undefined !== queryParams) {
    queryString = "?" + new URLSearchParams(queryParams);
  }
  const response = await axios
    .delete(baseUrl + path, config)
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((error) => {
      if (error.response.status === 401) {
        LogoutHelper();
      }
      return Promise.reject(error);
    });
  return response;
};

const makePatch = async (
  path: string,
  data?: any,
  queryParams?: Record<string, any>,
  config?: any,
): Promise<object> => {
  let queryString = "";
  if (undefined !== queryParams) {
    queryString = "?" + new URLSearchParams(queryParams);
  }
  const response = await axios
    .patch(baseUrl + path, data, config)
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((error) => {
      if (error.response.status === 401) {
        LogoutHelper();
      }
      return Promise.reject(error);
    });
  return response;
};

/**
 * Authenticate user on the API.
 *
 * @param {string} username
 * @param {string} password
 * @param {string} encodedPassword
 * @returns {Promise<object>}
 */

const userSignIn = async (
  username: string,
  password: string,
  encodedPassword: string = "",
): Promise<any> => {
  let data = {
    username: username,
    password: password,
    grant_type: "password",
    client_id: process.env.REACT_APP_SKYBRARY_API_CLIENT_ID,
    client_secret: process.env.REACT_APP_SKYBRARY_API_CLIENT_SECRET,
    scope: "*",
  };

  if (encodedPassword !== "") {
    data.edu_password = encodedPassword;
  }

  try {
    const response: any = await makePost("/oauth/token", data);
    const userResponse = await userGetMe(response.data.access_token);

    return {
      data: {
        userResponse,
        auth: {
          accessToken: response.data.access_token,
          expiresIn: response.data.expires_in,
          refreshToken: response.data.refresh_token,
        },
      },
      status: true,
    };
  } catch (error) {
    return {
      data: error,
      status: false,
    };
  }
};

const getCleverCode = async (code: string = ""): Promise<any> => {
  try {
    const response: any = await makePost(
      "/rrkidz/v6/clever/oauth/token/user?code=" + code,
    );
    return {
      data: {
        user: response.data.user,
        auth: {
          accessToken: response.data.auth.access_token,
          expiresIn: response.data.auth.expires_in,
          refreshToken: response.data.auth.refresh_token,
        },
      },
      status: true,
    };
  } catch (error) {
    return {
      data: error,
      status: false,
    };
  }
};

/**
 * Request a new Authentication token.
 *
 * @param accessToken
 * @param refreshToken
 */
const userRefreshToken = async (
  accessToken: string,
  refreshToken: string,
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const data = {
    refresh_token: refreshToken,
    grant_type: "refresh_token",
    client_id: process.env.REACT_APP_SKYBRARY_API_CLIENT_ID,
    client_secret: process.env.REACT_APP_SKYBRARY_API_CLIENT_SECRET,
    scope: "*",
  };

  try {
    const response = await makePost("/oauth/token", data, {}, config);
    return {
      data: {
        user: {},
        auth: {
          accessToken: response.data.access_token,
          expiresIn: response.data.expires_in,
          refreshToken: response.data.refresh_token,
        },
      },
      status: true,
    };
  } catch (error) {
    return {
      data: error,
      status: false,
    };
  }
};

/**
 * Get user information that is currently authenticated.
 *
 * @param {string} accessToken
 * @returns {Promise<object>}
 */
const userGetMe = async (token: any): Promise<any> => {
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const userInfoResponse: any = await makeGet(
      `${v6BasePath}/users/me`,
      {
        expand: "edu,edu_migration",
      },
      requestConfig,
    );
    const data = userInfoResponse.data;

    return {
      data: userInfoResponse.data,
      status: true,
    };
  } catch (error) {
    return {
      error: error,
      status: false,
    };
  }
};

const studentGetMe = async (token: any): Promise<any> => {
  try {
    const requestConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const userInfoResponse: any = await makeGet(
      `${v6BasePath}/students/users/me`,
      {},
      requestConfig,
    );
    return {
      data: userInfoResponse.data,
      status: true,
    };
  } catch (error) {
    return {
      error: error,
      status: false,
    };
  }
};

/**
 * Register user on the API.
 *
 * @param {string} username
 * @param {string} password
 * @returns {Promise<object>}
 */
const userRegister = async (
  username: string,
  password: string,
): Promise<object> => {
  const data = {
    emailAddress: username,
    password: password,
    storeId: 3,
    optIn: false,
    roles: ["parent"],
    type: "parent",
  };

  try {
    const response = await makePost(`${v6BasePath}/users`, data);
    return {
      data: response.data,
      status: true,
    };
  } catch (error) {
    return {
      data: error,
      status: false,
    };
  }
};

const getDashboardMessages = async (requestConfig: any): Promise<object> => {
  try {
    const messages: any = await makeGet(
      `${v6BasePath}/cms/edu/messages/dashboard`,
      {},
      requestConfig,
    );
    return {
      data: messages.data,
      status: true,
    };
  } catch (error) {
    return {
      data: error,
      status: false,
    };
  }
};

const getDashboardSubtitles = async (requestConfig: any): Promise<object> => {
  try {
    const subtitles: any = await makeGet(
      `${v6BasePath}/cms/edu/messages/subtitles`,
      {},
      requestConfig,
    );
    return {
      data: subtitles.data,
      status: true,
    };
  } catch (error) {
    return {
      data: error,
      status: false,
    };
  }
};

const getDashboardCurriculums = async (requestConfig: any): Promise<object> => {
  try {
    const curriculums: any = await makeGet(
      `${v6BasePath}/cms/edu/curriculums`,
      {},
      requestConfig,
    );
    return {
      data: curriculums.data,
      status: true,
    };
  } catch (error) {
    return {
      data: error,
      status: false,
    };
  }
};

/**
 * Retrieve dashboards data
 */
const getDashboardInfo = async (requestConfig: any): Promise<any> => {
  try {
    let [dashboardInfo, collapseSubtitles, curriculumData] = await Promise.all([
      getDashboardMessages(requestConfig),
      getDashboardSubtitles(requestConfig),
      getDashboardCurriculums(requestConfig),
    ]);
    if (
      !dashboardInfo.status ||
      (dashboardInfo.status && dashboardInfo.data.length)
    ) {
      dashboardInfo = DEFAULT_MESSAGES.data;
    } else {
      dashboardInfo = dashboardInfo.data[0].data;
    }
    if (!curriculumData.status) {
      curriculumData.data = [];
    }
    if (!collapseSubtitles.status) {
      collapseSubtitles.data = DEFAULT_SUBTITLES;
    }
    return {
      data: {
        admin: createAdminData(collapseSubtitles),
        parent: createParentData(collapseSubtitles),
        teachers: createTeachersData(dashboardInfo, collapseSubtitles),
        curriculum: curriculumData.data,
      },
    };
  } catch (error) {
    return {
      data: error,
      status: false,
    };
  }
};

/**
 * Creates a Payment Intent on Stripe.
 *
 * @param {number} amount
 * @param {string} email
 * @returns {Promise<any>}
 */
const createPaymentIntent = async (
  amount: number,
  email: string,
  accessToken: string,
): Promise<any> => {
  // Create PaymentIntent as soon as the page loads

  const data = {
    amount: amount,
    emailAddress: email,
    currency: defaultCurrency,
    payment_method_types: ["card"],
  };

  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };

  try {
    const response = await makePost(
      `${v6BasePath}/payments/intent`,
      data,
      {},
      config,
    );
    return {
      data: response.data,
      status: true,
    };
  } catch (error) {
    return {
      data: error,
      status: false,
    };
  }
};

/**
 * Creates a Guest Payment Intent on Stripe.
 *
 */
const createGuestPaymentIntent = async (
  amount: number,
  email: string,
  description: string,
): Promise<any> => {
  // Create PaymentIntent as soon as the page loads

  const data = {
    amount: amount,
    emailAddress: email,
    currency: defaultCurrency,
    description: description,
  };

  try {
    const response = await makePost(
      `${v6BasePath}/payments/guest-intent`,
      data,
      {},
      {},
    );
    return {
      data: response.data,
      status: true,
    };
  } catch (error) {
    return {
      data: error,
      status: false,
    };
  }
};

/**
 * Recover Password Step one
 *
 */

const RecoverPasswordStepOne = async (
  email: string,
  storeId: number,
): Promise<any> => {
  try {
    await makePost(`${v6BasePath}/users/password-reset`, {
      emailAddress: email,
      storeId: storeId,
    });
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Recover Password Step one
 *
 */
const RecoverPasswordStepTwo = async (token: string): Promise<any> => {
  try {
    const response = await makePost("/rrkidz/v6/users/validate-token", {
      token: token,
    });
    return { data: response.data, status: true };
  } catch (error) {
    return {
      data: error,
      status: false,
    };
  }
};

/**
 * Edit Password
 *
 */
const editPassword = async (
  email: string,
  token: string,
  newPassword: string,
): Promise<any> => {
  try {
    await makePatch(`${v6BasePath}/users/password-reset`, {
      emailAddress: email,
      token: token,
      newPassword: newPassword,
    });
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Get Library Books
 *
 */

const getAllBooks = async (
  requestConfig: any,
  bookTitle: any,
  bookListeningLevel: any,
  bookGenre: any,
  bookReadingLevel: any,
): Promise<any> => {
  try {
    const response = await makeGet(
      `${v6BasePath}/books`,
      {
        catalogId: 2,
        title: bookTitle,
        listeningLevel: bookListeningLevel,
        readingLevel: bookReadingLevel,
        genreId: bookGenre,
      },
      requestConfig,
    );

    return response;
  } catch (error) {
    return error;
  }
};

/**
 * Get School Info
 *
 */

const getSchoolinfo = async (
  requestConfig: any,
  schoolId: any,
): Promise<any> => {
  try {
    const response = await makeGet(
      `${v6BasePath}/schools/${schoolId}`,
      {},
      requestConfig,
    );
    return {
      data: response.data,
      status: true,
    };
  } catch (error) {
    return error;
  }
};

/**
 * Get locations
 *
 */
const getLocations = async () => {
  try {
    const response = await makeGet(`${v6BasePath}/locations`, {}, {});
    return {
      data: response,
      status: true,
    };
  } catch (error) {
    return {
      data: error,
      status: false,
    };
  }
};

/**
 * Creates a new School
 *
 */

const createSchool = async (data: any, accessToken: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };

  try {
    const response = await makePost(`${v6BasePath}/schools`, data, {}, config);
    return {
      data: response.data,
      status: true,
    };
  } catch (error) {
    return {
      data: error,
      status: false,
    };
  }
};

/**
 * Creates a new Order
 *
 */
const createOrder = async (data: any, accessToken: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };

  try {
    const response = await makePost(`${v6BasePath}/orders`, data, {}, config);
    return {
      data: response.data,
      status: true,
    };
  } catch (error) {
    return {
      data: error,
      status: false,
    };
  }
};

/* Edit School Info
 *
 */

const editSchoolInfo = async (
  requestConfig: any,
  values: any,
  schoolId: any,
): Promise<any> => {
  try {
    await makePatch(
      `${v6BasePath}/schools/${schoolId}`,
      values,
      {},
      requestConfig,
    );
    return true;
  } catch (error) {
    return error;
  }
};

/**
 * Creates a new Gift
 *
 */
const createGift = async (data: any): Promise<any> => {
  try {
    const response = await makePost(`${v6BasePath}/gifts/edu`, data, {}, {});
    return {
      data: response.data,
      status: true,
    };
  } catch (error) {
    return {
      data: error,
      status: false,
    };
  }
};

/**
 * Verify email to register if is available
 *
 */

const verifyEmail = async (email: any): Promise<any> => {
  try {
    const response: any = await makeGet(
      `${v6BasePath}/users/accounts`,
      { email: email },
      {},
    );
    return {
      status: true,
      data: response.data,
    };
  } catch (error) {
    return {
      status: false,
      data: error,
    };
  }
};

/**
 * Creates a trial user
 *
 */

const createUserSubscription = async (data: any): Promise<any> => {
  try {
    const response = await makePost(
      `${v6BasePath}/edu-subscriptions`,
      data,
      {},
      {},
    );
    return {
      data: response.data,
      status: true,
    };
  } catch (error) {
    return {
      data: error,
      status: false,
    };
  }
};

/**
 * Purchase by order form
 *
 */

const orderFormPurchase = async (data: any): Promise<any> => {
  try {
    const response = await makePost(
      `${v6BasePath}/edu-subscriptions/order-form`,
      data,
      {},
      {},
    );
    return {
      data: response.data,
      status: true,
    };
  } catch (error) {
    return {
      data: error,
      status: false,
    };
  }
};

const cleverOrderFormPurchase = async (
  requestConfig: any,
  data: any,
): Promise<any> => {
  try {
    const response = await makePost(
      `${v6BasePath}/clever/edu-subscriptions/order-form`,
      data,
      {},
      requestConfig,
    );
    console.log("internal res", response.data.data);
    return {
      data: response.data,
      status: true,
    };
  } catch (error) {
    return {
      data: error,
      status: false,
    };
  }
};

/**
 * Get student list
 *
 */

const getRosterStudents = async (
  requestConfig: any,
  schoolId: any,
  eduSubId: any,
): Promise<any> => {
  try {
    const response: any = await makeGet(
      `${v6BasePath}/schools/${schoolId}/subscriptions/${eduSubId}/rosters`,
      {},
      requestConfig,
    );
    return response.data;
  } catch (error) {
    return {
      data: error,
    };
  }
};

const getRosterStudentsClever = async (
  requestConfig: any,
  intSectionId: any,
): Promise<any> => {
  try {
    const response: any = await makeGet(
      `${v6BasePath}/clever/rosters/${intSectionId}`,
      {},
      requestConfig,
    );
    return response.data;
  } catch (error) {
    return {
      data: error,
    };
  }
};

/**
 * Get parent Rosters
 *
 */

const getParentRosters = async (
  parentId: any,
  requestConfig: any,
): Promise<any> => {
  try {
    const response = await makeGet(
      `${v6BasePath}/rosters/${parentId}/parent-reports`,
      {},
      requestConfig,
    );
    return {
      data: response.data,
      status: true,
    };
  } catch (error) {
    return {
      error: error,
      status: false,
    };
  }
};

/* Edit Student Info
 *
 */

const editStudentsInfo = async (
  requestConfig: any,
  data: any,
  schoolId: any,
  eduSubId: any,
  rosterId: any,
): Promise<any> => {
  try {
    await makePatch(
      `${v6BasePath}/schools/${schoolId}/subscriptions/${eduSubId}/rosters/${rosterId}`,
      {
        eduSubId: data.eduSubId,
        schoolId: data.schoolId,
        teacherId: data.teacherId,
        firstName: data.firstName,
        lastName: data.lastName,
        userName: data.userName,
        cleanPass: data.cleanPass,
        parentEmail: data.parentEmail,
        passType: data.passType,
      },
      {},
      requestConfig,
    );
    return { status: true };
  } catch (error) {
    return {
      status: false,
      data: error.response.data,
    };
  }
};

/**
 * Add Student to roster
 *
 */

const addStudentToRoster = async (
  requestConfig: any,
  data: any,
): Promise<any> => {
  try {
    await makePost(`${v6BasePath}/rosters`, data, {}, requestConfig);
    return { status: true };
  } catch (error) {
    return {
      status: false,
      data: error.response.data,
    };
  }
};

/**
 * Upload Students to roster
 *
 */

const uploadStudentToRoster = async (
  requestConfig: any,
  data: any,
  schoolId: any,
  eduSubscriptionsId: any,
): Promise<any> => {
  try {
    const response = await makePost(
      `${v6BasePath}/schools/${schoolId}/subscriptions/${eduSubscriptionsId}/rosters/collection`,
      data,
      {},
      requestConfig,
    );
    return { data: response.data.data };
  } catch (error) {
    return { data: error };
  }
};

/**
 * Get the state of a queued students .csv file
 *
 */

const uploadStudentsState = async (
  requestConfig: any,
  data: any,
  schoolId: any,
  eduSubscriptionsId: any,
  batchId: any,
): Promise<any> => {
  try {
    const response = await makeGet(
      `${v6BasePath}/schools/${schoolId}/subscriptions/${eduSubscriptionsId}/rosters/collection/${batchId}`,
      {},
      requestConfig,
    );
    return { data: response.data };
  } catch (error) {
    return { data: error };
  }
};

/* Delete All Students from roster
 *
 */

const deleteAllStudents = async (
  requestConfig: any,
  schoolId: any,
  eduSubId: any,
): Promise<any> => {
  try {
    await makeDelete(
      `${v6BasePath}/schools/${schoolId}/subscriptions/${eduSubId}/rosters`,
      {},
      requestConfig,
    );
    return true;
  } catch (error) {
    return false;
  }
};

/* Delete Single Student from roster
 *
 */

const deleteSingleStudent = async (
  requestConfig: any,
  schoolId: any,
  eduSubId: any,
  rosterId: any,
): Promise<any> => {
  try {
    await makeDelete(
      `${v6BasePath}/schools/${schoolId}/subscriptions/${eduSubId}/rosters/${rosterId}`,
      {},
      requestConfig,
    );
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Randomize roster passwords
 *
 */

const randomizeRosterPasswords = async (
  requestConfig: any,
  subscriptionId: any,
): Promise<any> => {
  try {
    await makePost(
      `${v6BasePath}/rosters/${subscriptionId}/passwords`,
      {},
      {},
      requestConfig,
    );
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Get Teacher List
 *
 */

const getTeachers = async (token: any, param: string): Promise<any> => {
  try {
    const response: any = await makeGet(
      `${v6BasePath}/schools/teachers`,
      { expand: param },
      token,
    );
    return {
      data: response.data,
    };
  } catch (error) {
    return {
      error: error,
      status: false,
    };
  }
};

const getTeacherOfDistrict = async (token: any): Promise<any> => {
  try {
    const response: any = await makeGet(
      `${v6BasePath}/clever/districts/teachers`,
      {},
      token,
    );
    return {
      data: response.data,
      status: true,
    };
  } catch (error) {
    return {
      error: error,
      status: false,
    };
  }
};

/**
 * Add teacher
 *
 */

const createTeacher = async (requestConfig: any, data: any): Promise<any> => {
  try {
    await makePost(`${v6BasePath}/schools/teachers`, data, {}, requestConfig);
    return {
      status: true,
    };
  } catch (error) {
    return {
      data: error,
      status: false,
    };
  }
};

/**
 * Upload teachers from csv
 *
 */

const uploadTeachersCsv = async (
  requestConfig: any,
  data: any,
  schoolId: any,
): Promise<any> => {
  try {
    const response = await makePost(
      `${v6BasePath}/schools/${schoolId}/teachers/collection`,
      data,
      {},
      requestConfig,
    );
    return {
      data: response,
      status: true,
    };
  } catch (error) {
    return {
      data: error,
      status: false,
    };
  }
};

/* Edit Teacher Info
 *
 */

const editTeacherInfo = async (
  requestConfig: any,
  values: any,
  id: any,
): Promise<any> => {
  try {
    await makePatch(
      `${v6BasePath}/schools/teachers/${id}`,
      {
        emailAddress: values.emailAddress,
        firstName: values.firstName,
        lastName: values.lastName,
      },
      {},
      requestConfig,
    );
    return true;
  } catch (error) {
    return false;
  }
};

/* Delete Teacher
 *
 */

const deleteTeacher = async (
  requestConfig: any,
  teacherId: any,
): Promise<any> => {
  try {
    await makeDelete(
      `${v6BasePath}/schools/teachers/${teacherId}`,
      {},
      requestConfig,
    );
    return { status: true };
  } catch (error) {
    return { status: false, data: error };
  }
};

/**
 * Validate gift token
 *
 */

const validateGiftToken = async (giftCode: string): Promise<any> => {
  try {
    const response = await makeGet(
      `${v6BasePath}/gifts/edu/${giftCode}`,
      {},
      {},
    );
    return {
      data: response.data.orders,
      status: response.data.status,
      gift: response.data.gifts,
    };
  } catch (error) {
    return false;
  }
};

/**
 * Redeem gift
 */

const redeemGift = async (
  access_token: any,
  accountData: any,
  isNew: boolean,
): Promise<any> => {
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  };
  try {
    if (isNew) {
      await makePatch(
        `${v6BasePath}/gifts/edu`,
        accountData,
        {},
        requestConfig,
      );
    } else {
      await makePatch(`${v6BasePath}/gifts/edu`, accountData, {}, {});
    }
    return true;
  } catch (error) {
    return false;
  }
};

/* Redeem Clever gift
 *
 */

const redeemCleverGift = async (
  access_token: any,
  accountData: any,
): Promise<any> => {
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  };
  try {
    await makePatch(
      `${v6BasePath}/clever/gifts/${accountData.token}`,
      {},
      {},
      requestConfig,
    );
    return true;
  } catch (error) {
    return false;
  }
};

/* Validate Discount Coupon
 *
 */

const validateCoupon = async (code: any): Promise<any> => {
  try {
    const response = await makeGet(
      `${v6BasePath}/payments/discounts/edu`,
      { code },
      {},
    );
    return response.data;
  } catch (error) {
    return false;
  }
};

/* Validate Discount Coupon
 *
 */

const getSchoolSubscriptions = async (
  requestConfig: any,
  schoolId: any,
  teacherId: any,
): Promise<any> => {
  if (teacherId !== null) {
    try {
      const response = await makeGet(
        `${v6BasePath}/schools/${schoolId}/subscriptions`,
        { teacherId: teacherId },
        requestConfig,
      );

      return response.data;
    } catch (error) {
      return {
        error: error,
        status: false,
      };
    }
  } else {
    try {
      const response = await makeGet(
        `${v6BasePath}/schools/${schoolId}/subscriptions`,
        {},
        requestConfig,
      );

      return response.data;
    } catch (error) {
      return {
        error: error,
        status: false,
      };
    }
  }
};

/**
 * Recover all the subscriptions associated with the clever teacher
 * @param requestConfig
 */
const getSchoolSubscriptionsForClever = async (
  requestConfig: any,
  perPage: any,
  page: any,
): Promise<any> => {
  try {
    let queryParam: any = {
      per_page: perPage || 10,
    };

    if (page) {
      queryParam.page = page;
    }

    const response = await makeGet(
      `${v6BasePath}/clever/edu-subscriptions/schools`,
      queryParam,
      requestConfig,
    );
    response.data.pagination = response.pagination;
    return response.data;
  } catch (error) {
    return {
      error: error,
      status: false,
    };
  }
};

/* Assign Teacher to Classroom
 *
 */

const editSubscription = async (
  requestConfig: any,
  schoolId: any,
  subId: any,
  data: any,
): Promise<any> => {
  try {
    await makePatch(
      `${v6BasePath}/schools/${schoolId}/subscriptions/${subId}`,
      data,
      {},
      requestConfig,
    );
    return { status: true };
  } catch (error) {
    return { status: false, data: error };
  }
};

/**
 * Send confirmation email to teachers
 *
 */

const emailToTeachers = async (
  requestConfig: any,
  schoolId: any,
  targets: any,
): Promise<any> => {
  try {
    await makePost(
      `${v6BasePath}/schools/${schoolId}/notifications`,
      { targets: targets },
      {},
      requestConfig,
    );
    return true;
  } catch (error) {
    return false;
  }
};

/* Get school Report
 *
 */

const getSchoolReports = async (
  requestConfig: any,
  schoolId: any,
): Promise<any> => {
  try {
    const response = await makeGet(
      `${v6BasePath}/schools/${schoolId}/reports`,
      {},
      requestConfig,
    );

    return response.data;
  } catch (error) {
    return {
      error: error,
      status: false,
    };
  }
};

/**
 * This could be used for clever teachers
 * @param requestConfig
 * @param schoolId
 */
const getSchoolReportsForClever = async (
  requestConfig: any,
  schoolId: Number,
  teacherId: any,
): Promise<any> => {
  try {
    let queryParam = {};
    if (teacherId) {
      queryParam.teacherId = teacherId;
    }
    const response = await makeGet(
      `${v6BasePath}/clever/schools/${schoolId}/reports`,
      queryParam,
      requestConfig,
    );

    return response.data;
  } catch (error) {
    return {
      error: error,
      status: false,
    };
  }
};

const getDistrictReportsForClever = async (
  requestConfig: any,
  teacherId: any,
  perPage: any,
  page: any,
): Promise<any> => {
  try {
    let queryParam: any = {
      per_page: perPage || 10,
    };
    if (teacherId) {
      queryParam.teacherId = teacherId;
    }
    if (page) {
      queryParam.page = page;
    }
    const response = await makeGet(
      `${v6BasePath}/clever/districts/reports`,
      queryParam,
      requestConfig,
    );

    response.data.pagination = response.pagination;

    return response.data;
  } catch (error) {
    return {
      error: error,
      status: false,
    };
  }
};

const getSchoolsOfCleverDistrict = async (requestConfig: any): Promise<any> => {
  try {
    const response = await makeGet(
      `${v6BasePath}/clever/districts/schools`,
      {},
      requestConfig,
    );

    return response;
  } catch (error) {
    return {
      error: error,
      status: false,
    };
  }
};

const getSummaryOfCleverDistrict = async (
  requestConfig: any,
  teacherId: any,
): Promise<any> => {
  try {
    let queryParam = {};
    if (teacherId) {
      queryParam.teacherId = teacherId;
    }
    const response = await makeGet(
      `${v6BasePath}/clever/districts/summary-report`,
      queryParam,
      requestConfig,
    );

    return {
      data: response.data,
      status: true,
    };
  } catch (error) {
    return {
      error: error,
      status: false,
    };
  }
};

/* Get Subscription Report
 *
 */

const getSubscriptionReports = async (
  requestConfig: any,
  subscriptionId: any,
): Promise<any> => {
  try {
    const response = await makeGet(
      `${v6BasePath}/rosters/${subscriptionId}/reports`,
      {},
      requestConfig,
    );

    return response.data;
  } catch (error) {
    return false;
  }
};

const getSubscriptionReportsForClever = async (
  requestConfig: any,
  internalSectionId: any,
): Promise<any> => {
  try {
    const response = await makeGet(
      `${v6BasePath}/clever/rosters/${internalSectionId}/reports`,
      {},
      requestConfig,
    );

    return response.data;
  } catch (error) {
    console.log("error", error);
    return false;
  }
};

/**
 * Send purchase email
 *
 */

const emailToBuyer = async (requestConfig: any, target: any): Promise<any> => {
  try {
    await makePost(
      `${v6BasePath}/edu-subscriptions/pay-for-me-email`,
      { toEmail: target },
      {},
      requestConfig,
    );
    return true;
  } catch (error) {
    return false;
  }
};

/* Create an order form to a trial account
 *
 */

const createTrialOrderForm = async (
  requestConfig: any,
  data: any,
): Promise<any> => {
  try {
    const response = await makePatch(
      `${v6BasePath}/edu-subscriptions/order-form`,
      data,
      {},
      requestConfig,
    );
    return {
      data: response.data,
      status: true,
    };
  } catch (error) {
    return {
      data: error,
      status: false,
    };
  }
};

/**
 * Add more subscription for an account
 *
 */

const addSubscriptions = async (
  requestConfig: any,
  data: any,
): Promise<any> => {
  try {
    const response = await makePost(
      `${v6BasePath}/edu-subscriptions/renew`,
      data,
      {},
      requestConfig,
    );
    return { status: true, data: response.data.data };
  } catch (error) {
    return { status: false };
  }
};

/**
 * Purchase and/or renew clever subscriptions
 * @param requestConfig
 * @param data
 */
const addCleverSubscriptions = async (
  requestConfig: any,
  data: any,
): Promise<any> => {
  try {
    const response = await makePost(
      `${v6BasePath}/clever/edu-subscriptions`,
      data,
      {},
      requestConfig,
    );
    console.log("native response", response);
    return { status: true, data: response.data.data };
  } catch (error) {
    return {
      data: error,
      status: false,
    };
  }
};

/**
 * Get clever subscription
 * @param requestConfig
 * @param showExpiredSubscription
 * @param page
 */
const getCleverSubscriptions = async (
  requestConfig: any,
  showExpiredSubscription: any,
  page: any,
  sort?: string,
): Promise<any> => {
  let queryParams = {
    page: page,
    show_expired: showExpiredSubscription,
    per_page: 10,
    sort: sort ? sort : null,
  };

  try {
    const response = await makeGet(
      `${v6BasePath}/clever/edu-subscriptions`,
      queryParams,
      requestConfig,
    );
    return {
      status: true,
      data: response.data,
      pagination: response.pagination,
    };
  } catch (error) {
    return {
      data: error,
      status: false,
    };
  }
};

/* Designate new admin to account
 *
 */

const designateAdmin = async (
  requestConfig: any,
  data: any,
  schoolId: any,
): Promise<any> => {
  try {
    await makePatch(
      `${v6BasePath}/schools/${schoolId}/designated`,
      data,
      {},
      requestConfig,
    );
    return {
      status: true,
    };
  } catch (error) {
    return {
      status: false,
      data: error,
    };
  }
};

/* Create an order form to a trial account
 *
 */

const editUserInfo = async (
  requestConfig: any,
  data: any,
  userId: number,
): Promise<any> => {
  try {
    const response = await makePatch(
      `${v6BasePath}/users/${userId}`,
      data,
      {},
      requestConfig,
    );
    return { status: true, data: response };
  } catch (error) {
    return {
      status: false,
      error: error,
    };
  }
};

/**
 * Send confirmation email to parents
 *
 */

const emailToParents = async (
  requestConfig: any,
  rosterId: any,
  targets: any,
  toAllParents: boolean,
  subscriptionId: any,
): Promise<any> => {
  if (toAllParents) {
    try {
      await makePost(
        `${v6BasePath}/rosters/${rosterId}/notifications`,
        { targets: targets, subscriptionId: subscriptionId },
        {},
        requestConfig,
      );
      return true;
    } catch (error) {
      return false;
    }
  } else {
    try {
      await makePost(
        `${v6BasePath}/rosters/${rosterId}/notifications/${targets}`,
        { rosterId: rosterId, subscriptionId: subscriptionId },
        {},
        requestConfig,
      );
      return true;
    } catch (error) {
      return false;
    }
  }
};

/**
 * Create order form from email
 *
 */

const emailOrderForm = async (data: any): Promise<any> => {
  try {
    const response = await makePost(
      `${v6BasePath}/edu-subscriptions/order-form-from-email`,
      data,
      {},
      {},
    );
    return {
      status: true,
      data: response.data,
    };
  } catch (error) {
    return {
      status: false,
    };
  }
};

/**
 * Create classroom hash
 *
 */

const getClassroomHash = async (classCode: any): Promise<any> => {
  try {
    const response = await makeGet(
      `${v6BasePath}/classrooms/code/${classCode}`,
      {},
      {},
    );
    return {
      data: response,
      status: true,
    };
  } catch (error) {
    return {
      error: error,
      status: false,
    };
  }
};

/**
 * Get Classroom by classHash
 *
 */

const classroomInfo = async (classHash: any, config: any): Promise<any> => {
  try {
    const classroom = await makeGet(
      `${v6BasePath}/classrooms/sign-in/${classHash}`,
      {},
      config,
    );
    return {
      status: true,
      data: classroom,
    };
  } catch (error) {
    return {
      status: false,
      data: {},
    };
  }
};

/**
 * Login a Student
 *
 */

const studentLogIn = async (hash: any, data: any): Promise<any> => {
  try {
    const signInResponse = await makePost(
      `${v6BasePath}/signin/login/${hash}`,
      data,
      {},
      {},
    );
    const userResponse = await studentGetMe(signInResponse.data.access_token);

    return {
      data: {
        user: userResponse.data,
        auth: {
          accessToken: signInResponse.data.access_token,
          expiresIn: signInResponse.data.expires_in,
          refreshToken: signInResponse.data.refresh_token,
        },
      },
      status: true,
    };
  } catch (error) {
    return {
      data: error,
      status: false,
    };
  }
};

/**
 *  Edit a students password
 */

const editStudentPassword = async (
  data: any,
  token: string | undefined,
): Promise<any> => {
  try {
    const response = await makePatch(
      `${v6BasePath}/change-password/${token}`,
      data,
      {},
      {},
    );
    return {
      status: true,
      data: response,
    };
  } catch (error) {
    return false;
  }
};

/**
 * Get the Upload Teachers by csv status
 */

const getTeacherUploadStatus = async (
  batchId: string,
  schoolId: any,
  config: any,
): Promise<any> => {
  try {
    const response = await makeGet(
      `${v6BasePath}/schools/${schoolId}/teachers/collection/${batchId}`,
      {},
      config,
    );
    return {
      status: true,
      data: response,
    };
  } catch (error) {
    return {
      status: false,
      data: error,
    };
  }
};
/**
 * Assign a trial to another school
 *
 */

const migrateTrial = async (id: any, requestConfig: any): Promise<any> => {
  try {
    const response = await makePost(
      `${v6BasePath}/adm/migrate/${id}`,
      {},
      {},
      requestConfig,
    );
    return response;
  } catch (error) {
    return false;
  }
};

/**
 * Confirm o cancel a teacher migration
 *
 */

const migrateTrialConfirm = async (
  choice: string,
  requestConfig: any,
): Promise<any> => {
  try {
    const response = await makePost(
      `${v6BasePath}/teachers/migrate/${choice}`,
      {},
      {},
      requestConfig,
    );
    return {
      status: true,
      data: response,
    };
  } catch (error) {
    return {
      status: false,
      data: error,
    };
  }
};

/**
 * Admin Cancels a migration
 *
 */

const cancelMigration = async (
  teacherId: string,
  requestConfig: any,
): Promise<any> => {
  try {
    await makeDelete(
      `${v6BasePath}/adm/migrate/${teacherId}/cancel`,
      {},
      requestConfig,
    );
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Get catalogs
 *
 */

const getCatalogs = async (
  config: any,
  page: number,
  bookTitle: string,
  bookListeningLevel: string,
  bookReadingLevel: string,
  bookGenre: number,
  type: number,
  unitLevel: number,
  islandId: number,
  sort: string,
  items: number | null,
) => {
  let data = {
    page: page,
    listeningLevel: bookListeningLevel,
    readingLevel: bookReadingLevel,
    genreId: bookGenre,
    type: type,
    unitThemeId: unitLevel,
    islandId: islandId,
    sort: sort,
  };

  if (bookTitle.trim() !== "") {
    data.word = bookTitle;
  }

  if (items !== null) {
    data.items = items;
  }

  try {
    const response = await makeGet(`${v6BasePath}/catalogs`, data, config);
    return response;
  } catch (error) {
    return error;
  }
};

const getMenuItems = async (): Promise<any> => {
  const response = await axios
    .get(baseUrlConsumer + `/entity/menu/main/tree`, {})
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });

  return response;
};

const getFooterItems = async (): Promise<any> => {
  const response = await axios
    .get(`${baseUrlConsumer}/entity/menu/edu-footer/tree`)
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });

  return response;
};

const getClassroomData = async (token: string) => {
  let studentToken = token;

  try {
    const response = await makeGet(
      `${v6BasePath}/classrooms/students/${studentToken}`,
      {},
      {},
    );
    return {
      data: response.data,
      status: true,
    };
  } catch (error) {
    return {
      data: error,
      status: false,
    };
  }
};

const cleverLogIn = async (code: any): Promise<any> => {
  let returnedObject = {};
  try {
    const cleverLogin = await getCleverCode(code);
    if (cleverLogin.status === false) {
      const responseData = JSON.parse(cleverLogin.data.request.response);
      returnedObject = {
        data: responseData.errorMessage,
        status: false,
      };
    } else {
      returnedObject = {
        data: {
          user: cleverLogin.data.user,
          auth: {
            accessToken: cleverLogin.data.auth.accessToken,
            expiresIn: cleverLogin.data.auth.expiresIn,
            refreshToken: cleverLogin.data.auth.refreshToken,
          },
        },
        status: true,
      };
    }
  } catch (error) {
    returnedObject = {
      data: error,
      status: false,
    };
  }
  return returnedObject;
};

const getStudentSubscription = async (
  intCleverUserId: Number,
  token: String,
  cleverBearerAccessToken: string,
): Promise<any> => {
  try {
    const requestConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
        cleveraccesstoken: cleverBearerAccessToken,
      },
    };
    const response: any = await makeGet(
      `/rrkidz/v6/students/clever/user/${intCleverUserId}/subscription`,
      {},
      requestConfig,
    );
    return {
      data: response.data,
      status: true,
    };
  } catch (error) {
    return {
      data: error,
      status: false,
    };
  }
};

const getCleverSubscriptionById = async (
  subscriptionId: String | undefined,
  token: String,
  cleverBearerAccessToken: String,
): Promise<any> => {
  let controller = new AbortController();

  try {
    const requestConfig = {
      signal: controller.signal,
      headers: {
        Authorization: `Bearer ${token}`,
        cleveraccesstoken: cleverBearerAccessToken,
      },
    };
    const response: any = await makeGet(
      `/rrkidz/v6/clever/edu-subscriptions/${subscriptionId}`,
      {},
      requestConfig,
    );
    return {
      data: response.data,
      status: true,
    };
  } catch (error) {
    return {
      data: error,
      status: false,
    };
  }
};

const getCleverSubscriptionByMultipleIds = async (
  renewalsIds: Array<string>,
  token: string,
  cleverBearerAccessToken: string,
): Promise<any> => {
  let controller = new AbortController();

  try {
    const requestConfig: RequestInit = {
      signal: controller.signal,
      headers: {
        Authorization: `Bearer ${token}`,
        cleveraccesstoken: cleverBearerAccessToken,
      },
    };

    let data = {
      subscriptionsId: renewalsIds,
    };

    const response: any = await makeGet(
      `/rrkidz/v6/clever/edu-subscriptions/list`,
      data,
      requestConfig,
    );

    return {
      data: response.data,
      status: true,
    };
  } catch (error) {
    return {
      data: error,
      status: false,
    };
  }
};

export {
  makeGet,
  makePost,
  makeDelete,
  makePatch,
  userSignIn,
  userGetMe,
  userRegister,
  getDashboardInfo,
  createPaymentIntent,
  RecoverPasswordStepOne,
  RecoverPasswordStepTwo,
  editPassword,
  getLocations,
  createSchool,
  createOrder,
  getAllBooks,
  getSchoolinfo,
  editSchoolInfo,
  createGuestPaymentIntent,
  createGift,
  verifyEmail,
  createUserSubscription,
  orderFormPurchase,
  addStudentToRoster,
  uploadStudentToRoster,
  uploadStudentsState,
  getRosterStudents,
  editStudentsInfo,
  deleteAllStudents,
  deleteSingleStudent,
  randomizeRosterPasswords,
  getTeachers,
  createTeacher,
  uploadTeachersCsv,
  editTeacherInfo,
  deleteTeacher,
  validateGiftToken,
  redeemGift,
  redeemCleverGift,
  validateCoupon,
  getSchoolSubscriptions,
  editSubscription,
  emailToTeachers,
  getSchoolReports,
  getSubscriptionReports,
  emailToBuyer,
  createTrialOrderForm,
  addSubscriptions,
  designateAdmin,
  editUserInfo,
  emailToParents,
  emailOrderForm,
  classroomInfo,
  getClassroomHash,
  userRefreshToken,
  studentLogIn,
  migrateTrial,
  cancelMigration,
  editStudentPassword,
  getTeacherUploadStatus,
  migrateTrialConfirm,
  getCatalogs,
  getMenuItems,
  getFooterItems,
  getParentRosters,
  getClassroomData,
  studentGetMe,
  cleverLogIn,
  getStudentSubscription,
  addCleverSubscriptions,
  getCleverSubscriptions,
  cleverOrderFormPurchase,
  getSchoolSubscriptionsForClever,
  getRosterStudentsClever,
  getSchoolReportsForClever,
  getSubscriptionReportsForClever,
  getSchoolsOfCleverDistrict,
  getTeacherOfDistrict,
  getDistrictReportsForClever,
  getSummaryOfCleverDistrict,
  getCleverSubscriptionById,
  getCleverSubscriptionByMultipleIds,
};
