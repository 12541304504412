import { useContext } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import SchoolManagement from "./components/Dashboard/Admin/AdminSections/AdminManageAccount/SchoolManagement";
import AdminPurchase from "./components/Dashboard/Admin/AdminSections/AdminPurchase/AdminPurchase";
import AdminPurchaseCard from "./components/Dashboard/Admin/AdminSections/AdminPurchase/AdminPurchaseCard";
import AdminSuccess from "./components/Dashboard/Admin/AdminSections/AdminPurchase/AdminSuccess";
import ReportDetail from "./components/Dashboard/Admin/AdminSections/AdminReports/ReportDetail";
import AdminReports from "./components/Dashboard/Admin/AdminSections/AdminReports/Reports";
import AdminDashboard from "./components/Dashboard/Admin/AdminSections/Dashboard/Body/AdminDashboard";
import ManageSubscription from "./components/Dashboard/Admin/AdminSections/ManageSubscriptions/ManageSubscriptions";
import ManageTeacher from "./components/Dashboard/Admin/AdminSections/ManageTeachers/ManageTeachers";
import SetNewPassword from "./components/Dashboard/Admin/AdminSections/SetNewPassword";
import Help from "./components/Dashboard/Common/Pages/Help";
import MyAccount from "./components/Dashboard/Common/Pages/MyAccount";
import ParentDashboard from "./components/Dashboard/Parent/ParentSections/Dashboard/ParentDashboard";
import ParentReports from "./components/Dashboard/Parent/ParentSections/ParentReports/ParentReports";
import ParentTos from "./components/Dashboard/Parent/ParentSections/ParentTos/ParentTos";
import TeacherClassRoster from "./components/Dashboard/Teacher/TeacherSections/ClassRoster/ClassRoster";
import EditTeacherClassRoster from "./components/Dashboard/Teacher/TeacherSections/ClassRoster/EditClassRoster";
import PrintPasswords from "./components/Dashboard/Teacher/TeacherSections/ClassRoster/PrintPasswords";
import TeacherCurriculum from "./components/Dashboard/Teacher/TeacherSections/Curriculum/Curriculums";
import TeacherDashboard from "./components/Dashboard/Teacher/TeacherSections/Dashboard/TeacherDashboard";
import TeacherLibrary from "./components/Dashboard/Teacher/TeacherSections/Library/Library";
import TeacherCardInfo from "./components/Dashboard/Teacher/TeacherSections/Purchase/TeacherCardInfo";
import TeacherGift from "./components/Dashboard/Teacher/TeacherSections/Purchase/TeacherGift";
import TeacherPurchase from "./components/Dashboard/Teacher/TeacherSections/Purchase/TeacherPurchase";
import TeacherReportDetail from "./components/Dashboard/Teacher/TeacherSections/Reports/ReportDetail";
import TeacherReport from "./components/Dashboard/Teacher/TeacherSections/Reports/Reports";
import MigrateConfirm from "./components/Forms/migrate-teacher/migrate-confirm";
import NotFound from "./components/NotFound";
import RedirectToConsumer from "./components/RedirectToConsumer";
import Unauthorized from "./components/Unauthorized";
import UserProvider from "./context/user";
import Clever from "./pages/clever/Clever";
import DashboardLayout from "./pages/dashboard/DashboardLayout";
import Gift from "./pages/gift/Gift";
import AccountInfoRedeem from "./pages/gift/Redeem/AccountInfo";
import Redeem from "./pages/gift/Redeem/Redeem";
import SubscriptionInfo from "./pages/gift/Redeem/SubscriptionInfo";
import ChangePassword from "./pages/parents/ChangePassword";
import ParentForgotPassword from "./pages/parents/ForgotPassword";
import ParentLogIn from "./pages/parents/ParentLogIn";
import ParentSignIn from "./pages/parents/SignIn";
import WelcomeStudents from "./pages/parents/WelcomeStudents";
import CardInfo from "./pages/purchase/card";
import Checkout from "./pages/purchase/checkout";
import Info from "./pages/purchase/info";
import Purchase from "./pages/purchase/purchase";
import UpgradeSubscription from "./pages/purchase/UpgradeSubscriptions/UpgradeSubscription";
import UpgradeSubscriptionEmail from "./pages/purchase/UpgradeSubscriptions/UpgradeSubscriptionEmail";
import Success from "./pages/try_it_free/success";
import TryItFree from "./pages/try_it_free/try_it_free";
import EditPassword from "./pages/user/EditPassword";
import RecoverPassword from "./pages/user/RecoverPassword";
import RecoverPasswordStepTwo from "./pages/user/RecoverPasswordStepTwo";
import SignIn from "./pages/user/sign-in";

import {
  ManageAnonymous,
  ManageSetPassword,
  ManageTrial,
  ManageUserAdmin,
  ManageUserParent,
  ManageUserTeacher,
  RequireAuth,
} from "./context/user";

import UnauthorizedCleverMessage from "./components/Clever/UnauthorizedCleverMessage";
import { NotForCleverUser } from "./context/clever";
import "./styles/globals.scss";
import RedeemForm from "@components/Forms/gift/Redeem/RedeemForm";

function App() {
  const userContext = useContext(UserProvider);

  return (
    <Router>
      <div
        className={`App ${
          userContext.formProgress > 0 ? "submit-loading" : ""
        }`}
      >
        <Routes>
          {/* Pages that doesn't require auth */}
          <Route path="/" element={<RedirectToConsumer />} />
          <Route path="/clever" element={<Clever />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route
            path="/clever-unauthorized"
            element={<UnauthorizedCleverMessage />}
          />
          <Route
            path="/purchase/success"
            element={<Success page="purchase" />}
          />

          <Route path="/*" element={<NotFound />} />

          <Route element={<ManageAnonymous />}>
            <Route
              path="/try-it-free"
              element={<TryItFree page="try-it-free" form="try_it_free" />}
            />
            <Route
              path="/try-it-free/success"
              element={<Success page="try_it_free" />}
            />
            <Route
              path="/try-it-free/info"
              element={<Info page="try-it-free" form="try_it_free" />}
            />

            <Route
              path="/purchase/checkout/card"
              element={<CardInfo page="purchase" form="purchase" />}
            />
            <Route
              path="/purchase/checkout"
              element={<Checkout page="purchase" form="purchase" />}
            />
            <Route
              path="/purchase/info"
              element={<Info page="purchase" form="purchase" />}
            />
            <Route
              path="/purchase"
              element={<Purchase page="purchase" form="purchase" />}
            />
            <Route path="/user/:id/edit-password" element={<EditPassword />} />
            <Route
              path="/user/reset/:id/:time/:token"
              element={<RecoverPasswordStepTwo />}
            />
            <Route path="/user/password" element={<RecoverPassword />} />
            <Route
              path="/user"
              element={<SignIn />}
            />
            <Route path="/user/login" element={<SignIn />} />
            <Route
              path="/purchase/from-trial/mail/:user"
              element={
                <Purchase
                  page="try_it_free_purchase_email"
                  form="try_it_free_purchase_email"
                />
              }
            />
            <Route
              path="/purchase/from-trial/mail/:user/checkout"
              element={
                <Checkout
                  page="try_it_free_purchase_email"
                  form="try_it_free_purchase_email"
                />
              }
            />
            <Route
              path="/purchase/from-trial/mail/:user/card"
              element={
                <CardInfo
                  page="try_it_free_purchase_email"
                  form="try_it_free_purchase_email"
                />
              }
            />
          </Route>
          <Route
            path="/sigin/change-password/:id"
            element={<ChangePassword />}
          />
          <Route
            path="/sigin/forgot-password"
            element={<ParentForgotPassword />}
          />
          <Route path="/sigin/login/:userToken" element={<ParentLogIn />} />
          <Route path="/sigin/parent/:classHash" element={<ParentSignIn />} />
          <Route
            path="/sigin/classroom/:classHash"
            element={<ParentSignIn />}
          />
          <Route
            path="/student/classroom/access"
            element={<WelcomeStudents />}
          />

          <Route path="/gift/success" element={<Success page="gift" />} />
          <Route
            path="/gift/checkout/payment"
            element={<CardInfo page="gift" form="gift" />}
          />
          <Route
            path="/gift/checkout"
            element={<Checkout page="gift" form="gift" />}
          />
          <Route path="/gift" element={<Gift />} />
          <Route
            path="/gift/redeem/account-info"
            element={<AccountInfoRedeem page="redeem" />}
          />
          <Route
            path="/gift/redeem/subscription-info"
            element={<SubscriptionInfo page="redeem" />}
          />
          <Route
            path="/gift/redeem/manual"
            element={<RedeemForm page="redeem" />}
          />
          <Route
            path="/gift/redeem/your-info"
            element={<TryItFree page="redeem" form="redeem" />}
          />
          <Route
            path="/gift/redeem/success"
            element={<Success page="redeem" />}
          />
          <Route
            path="/gift/redeem/school-info"
            element={<Info page="redeem" form="redeem" />}
          />
          <Route
            path="/gift/redeem/:giftToken"
            element={<RedeemForm page="redeem" />}
          />

          {/* Pages that require auth */}
          <Route element={<RequireAuth />}>
            <Route element={<DashboardLayout from="teacher" />}>
              <Route element={<ManageUserTeacher />}>
                <Route
                  path="/teachers/dashboard"
                  element={<TeacherDashboard />}
                />
                <Route
                  path="/teachers/manage/rosters"
                  element={<TeacherClassRoster />}
                />
                <Route
                  path="/teachers/manage/rosters/:subscriptionId"
                  element={<EditTeacherClassRoster />}
                />

                <Route path="/teachers/reports" element={<TeacherReport />} />

                <Route
                  path="/teachers/reports/classroom/:subscriptionId"
                  element={<TeacherReportDetail />}
                />

                <Route
                  path="/teachers/curriculum"
                  element={<TeacherCurriculum />}
                />

                <Route path="/teachers/library" element={<TeacherLibrary />} />
                <Route element={<NotForCleverUser />}>
                  <Route
                    path="/teachers/manage/account"
                    element={<MyAccount rol="teacher" />}
                  />
                </Route>
                <Route
                  path="/teachers/pages/help"
                  element={<Help rol="teacher" />}
                />
                <Route path="/teachers/gift" element={<TeacherGift />} />
                <Route
                  path="/teachers/gift/checkout"
                  element={<TeacherPurchase />}
                />
                <Route
                  path="/teachers/gift/checkout/payment"
                  element={<TeacherCardInfo />}
                />
              </Route>
            </Route>

            <Route element={<DashboardLayout from="admin" />}>
              <Route element={<ManageUserAdmin />}>
                <Route path="/adm/dashboard" element={<AdminDashboard />} />
                <Route path="/adm/pages/help" element={<Help rol="admin" />} />
                {/* <Route element={<ManageOrder />}> */}
                <Route
                  path="/adm/reports/classroom/:subscriptionId"
                  element={<ReportDetail />}
                />
                <Route
                  path="/adm/manage/school"
                  element={<SchoolManagement />}
                />
                <Route path="/adm/school/teacher" element={<ManageTeacher />} />
                <Route path="/adm/reports" element={<AdminReports />} />
                <Route path="/adm/checkout" element={<AdminPurchase />} />
                <Route
                  path="/adm/checkout/card"
                  element={<AdminPurchaseCard />}
                />
                <Route
                  path="/adm/checkout/success"
                  element={<AdminSuccess />}
                />
                {/* </Route> */}
                <Route path="/adm/manage" element={<MyAccount rol="admin" />} />
                <Route path="/adm/school" element={<ManageSubscription />} />
                <Route
                  path="/adm/gift/redeem/manual"
                  element={<Redeem page="redeem" />}
                />
              </Route>
            </Route>
            <Route element={<DashboardLayout from="parent" />}>
              <Route element={<ManageUserParent />}>
                <Route path="parent/dashboard" element={<ParentDashboard />} />
                <Route path="parent/reports" element={<ParentReports />} />
                <Route path="parent/pages/tos" element={<ParentTos />} />
                <Route
                  path="/parent/manage"
                  element={<MyAccount rol="parent" />}
                />
                <Route
                  path="/parent/pages/help"
                  element={<Help rol="parent" />}
                />
              </Route>
            </Route>

            <Route
              path="/teachers/migrate/confirm"
              element={<MigrateConfirm />}
            />
            <Route element={<ManageUserTeacher />}>
              <Route
                path="/teachers/classroom/print/:classId"
                element={<PrintPasswords />}
              />
            </Route>
            <Route element={<ManageUserAdmin />} />
            <Route element={<ManageTrial />}>
              <Route
                path="/purchase/from-trial/how"
                element={<UpgradeSubscription />}
              />
              <Route
                path="/purchase/from-trial/mail"
                element={<UpgradeSubscriptionEmail />}
              />
              <Route
                path="/purchase/from-trial/card"
                element={
                  <CardInfo
                    page="try_it_free_purchase"
                    form="try_it_free_purchase"
                  />
                }
              />
              <Route
                path="/purchase/from-trial"
                element={
                  <Checkout
                    page="try_it_free_purchase"
                    form="try_it_free_purchase"
                  />
                }
              />
            </Route>
          </Route>
          <Route element={<ManageSetPassword />}>
            <Route path="/set-password" element={<SetNewPassword />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
